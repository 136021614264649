.socialButton {
    margin-right : 3px;
    cursor       : pointer;
    transition   : all .2s ease-in-out;
}

.socialButton:hover {
    transform: scale(1.1);
}

.socialbar {
    margin : 0 20px 0 0 !important;
    padding : 0 !important; 
}

.releaseSpacing {
    margin: 0 !important;
    padding: 0 !important;
}