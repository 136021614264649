a, .simulatedLink {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Raleway", "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
  color: #4183c4 !important;
}

a:hover, .simulatedLink:hover, .buttonLink:hover {
  color: #ED1C24 !important;
}

.buttonLink {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Raleway", "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif !important;
  color: #4183c4 !important;
  background: none!important;
  border: none;
  padding: 0!important;
  font-weight: 100 !important;
}

p {
  font-size: 1.1em !important;
  font-family: "Poppins";
}

h1, h2, h3, h4, h5 {
  font-family: "Poppins" !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.rounded-button-link {
  text-align: center;
  display: inline-block;
  margin: 0.75em;
  padding: 1.35em 1.1em;
  width: 15rem;
  background: #00aeef;
  color: #FFF !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 800;
  border-top-left-radius: 20px 50px;
  border-top-right-radius: 20px 50px;
  border-bottom-right-radius: 20px 50px;
  border-bottom-left-radius: 20px 50px;
  cursor: pointer;
  font-size: 1.1rem;
}

@media all and (min-width: 362px) and (max-width: 991px) {
  /* Phones & Tablets */

  .rounded-button-link {
    width: 11rem;
    font-size: 0.9rem;
  }
}

.videoRatioBox
{
  padding-top: 75%;
  height: 0;
  position: relative;
}

.videoRatioBox Iframe
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.center {
  text-align: center;
}



body {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Raleway", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

h2.siteSubtitle
{
  font-size: 1.3em !important;
}

/* Table Override */

.ui.sortable.table {
  border-radius: 20px 20px 10px 10px !important;
  background-color: #F0FAFF !important;
}

.ui.sortable.table thead th {
  cursor: pointer;
  white-space: nowrap;
  border-left: none !important;
  border-bottom: none !important;
  color: #fff !important;
  background-color: #00aeef;
  font-size:  1.1rem;
  font-weight: 500 !important;
}

.ui.sortable.table thead th:hover {
  color :#ED1C24 !important;
  background-color: #00aeef !important;
}

.ui.sortable.table thead th:first-child {
  border-radius: 20px 0 0 0 !important;
}

.ui.sortable.table thead th:last-child {
  border-radius: 0 20px 0 0 !important;
}

.ui.sortable.table thead th.sorted {
  background-color: #00aeef !important;
}

.ui.table.metadataTable tr td { border-top: 0px !important; }

.movesTableIcon
{
  position: relative;
  bottom: 1px;
}

/* Paginator Override */

.ui.pagination.menu .item {
  background-color: #F0FAFF;
}

.ui.pagination.menu {
  border-radius: 20px 20px 20px 20px;
}

.ui.pagination.menu .item:first-child {
  border-radius: 20px 0 0 20px;
  background-color: #ceefff !important;
}

.ui.pagination.menu .item:last-child {
  border-radius: 0 20px 20px 0 !important;
  background-color: #ceefff !important;
}

.ui.pagination.menu .active.item {
  background-color: #ceefff !important;
  font-weight: 700;
}

.ui.pagination.menu .item:hover {
  background-color: #ceefff !important;
  font-weight: 600;
}

.ui.pagination.menu .active.item:hover {
  font-weight: 700;
}

/* Filter Container */
.ui.grid {
  margin-top: 0 !important;
}

.filterContainer {
  background: #F0FAFF;
  border-radius: 20px;
  border-color: rgba(34, 36, 38, 0.15) !important;
  border-width: 1px !important;
  border-style: solid !important;
  padding: 0px 0px 14px 0px !important;
}

.filterHeader {
  background-color: #00aeef;
  border-radius: 20px 20px 0 0;
  text-align: center;
  color: #F0FAFF;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

/* Video Tabs */
.videoTabs{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: none !important;
}

.videoTabs a.item{
  background: #F0FAFF !important;
  border-top-left-radius: 20px 50px !important;
  border-top-right-radius: 20px 50px !important;
  border-bottom-right-radius: 20px 50px !important;
  border-bottom-left-radius: 20px 50px !important;
  padding: 10px !important;
  margin-left: 0px !important;
  margin-right: 7px !important;
  margin-bottom: 7px !important;
}

.videoTabs a.item:hover:not(.active){
  color: #ED1C24 !important;
}

.videoTabs a.active{
  background: #00aeef !important;
  color: #F0FAFF !important;
}

.videoTabs a.item:last-child{
  margin-right: 0px;
}

/* Video tabs pane */

.ui.attached.segment{
  border: none !important;
  margin-bottom: 10px !important;
  padding: 0 !important;
}

/* Move Metadeta */
.metadataContainer {
  border-radius: 20px 20px 20px 20px !important;
  background-color: #F0FAFF !important;
  padding: 10px 20px 10px 20px;
}

.moveTitle {
  display: inline;
  margin-left : 37px
}

/* List */
.ui.list .list>.item, .ui.list>.item, ol.ui.list li, ul.ui.list li {
  padding: 0.1em !important;
  line-height: 1.4285em !important;
}

/* Menu Bar Override */
.ui.menu .ui.dropdown .menu.mymenu>.item {
  padding: 0 !important;
}

.item::before {
  width: 0px !important;
}

/* Side Bar Override */
/* For Drop down in menu
 .ui.sidebar.menu {
  overflow-y : visible !important;
} */

.ui.icon.sidebar.menu .item {
  text-align: left;
}

/* Shared Stylings */
.lightbox {
  border-radius: 20px 20px 20px 20px;
  background-color: #F0FAFF;
  padding: 20px 20px 20px 20px;
  margin: 20px 0 20px 0;
}

.section {
  margin: 40px 0 40px 0;
}

/* Paragraph */

.bold {
  font-weight: 700;
}

.textJustify {
  text-align: justify;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.marginTop {
  margin-top: 10px;
}

.marginTopMedium {
  margin-top: 20px !important;
}

.marginTopHuge {
  margin-top: 50px !important;
}

.marginTopMassive {
  margin-top: 100px !important;
}

.paddingTop {
  padding-top: 10px;
}

.colorMediumBlue {
  color: #A6E2FE;
} 
.colorStrongBlue {
  color: #4183c4;
} 

.floatRight {
  float: right;
}

.floatLeft {
  float: left;
}

.inline {
  display: inline;
}

.marginLeftAuto {
  margin-left: auto !important;
}

.marginCenter {
  margin-left: auto !important;
  margin-right: auto !important;
}

/* toggleImage */
.toggleImage {
  cursor: pointer;
}

.toggleImage:hover .label {
  background-color:#ceefff;
}

.toggleImage.activeImageToggle .label  {
  background-color: #A6E2FE;
}

/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/

.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
